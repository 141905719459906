import { IonContent, IonModal, IonPage } from '@ionic/react'
import { Patient } from 'domain/usecases/get-patient'
import { PatientItem } from 'domain/usecases/list-patients'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { UserService } from 'infra/services/user-service'
import { route } from 'main/routes'
import { Viewport } from 'presentation/components'
import Footer from 'presentation/components/footer/footer'
import { Header } from 'presentation/organisms/header/header'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import ModalEditReportHeader from '../report/organisms/modal-edit-report-header/modal-edit-report-header'
import ReportHistory from './history/history'
import LastOutcomeProgress from './last-outcome-progress/last-outcome-progress'
import LatestReport from './latest-report/latest-report'
import PatientHeading from './patient-heading/patient-heading'
import PatientInformation from './patient-information/patient-information'
import './patient.scss'
import PrescribedTherapy from './prescribed-therapy/prescribed-therapy'

interface PatientPageProps {
  userService: UserService
  patientsService: PatientsService
  reportsService: ReportsService
}

const PatientPage: React.FC<PatientPageProps> = (props) => {
  const navigation = useHistory()
  const modalEdit = useRef<HTMLIonModalElement>(null)

  const [patientItem, setPatientItem] = useState<Patient>({} as Patient)
  const [isModalEditOpen, setModalEditOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const getPatient = () => {
    setIsLoading(true)
    props.patientsService
      .getPatient({ mrn: patientItem.MRN })
      .then((patient) => setPatientItem(patient))
      .catch(() => null)
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (patientItem.MRN) getPatient()
  }, [patientItem.MRN])

  useEffect(() => {
    const state = navigation.location.state as { patientItem: PatientItem }

    if (!state || !state.patientItem) navigation.replace(route.dashboard.url)
    else
      setPatientItem({
        MRN: state.patientItem.MRN,
        age: state.patientItem.age,
        birthdate: state.patientItem.birthdate,
        sex: state.patientItem.sex,
        name: state.patientItem.name,
      } as Patient)
  }, [navigation])

  return (
    <IonPage className="patient-page">
      <IonContent>
        <Header userService={props.userService} tab={'patients'} injectedQuery={patientItem?.cancer_type as string} />
        <PatientHeading
          reportsService={props.reportsService}
          patientsService={props.patientsService}
          patientItem={patientItem}
        />
        <Viewport className="viewport-patient-information-page">
          <section className="patient-information-grid">
            <div>
              <PatientInformation
                patientItem={patientItem}
                onEditPatientInformation={() => setModalEditOpen(true)}
                isLoading={isLoading}
              />

              <LastOutcomeProgress
                isLoading={isLoading}
                title="Latest Progress Note"
                patientsService={props.patientsService}
                patient={patientItem}
                note={patientItem.patient_notes && patientItem.patient_notes[0] && patientItem.patient_notes[0].note}
                onUpdatePatient={getPatient}
              />
            </div>

            <div>
              <LatestReport
                isLoading={isLoading}
                latestReport={patientItem.last_reports && patientItem.last_reports[0]}
                reportNumber={patientItem.last_reports && patientItem.last_reports.length}
              />

              <PrescribedTherapy isLoading={isLoading} treatments={patientItem.prescribed_therapy?.treatments} />
            </div>
          </section>

          <ReportHistory lastReports={patientItem.last_reports} isLoading={isLoading} patient={patientItem} />

          <IonModal
            ref={modalEdit}
            isOpen={isModalEditOpen}
            onDidDismiss={(info) => {
              if (info.detail.data && info.detail.data.wasEdited) getPatient()
              setModalEditOpen(false)
            }}
          >
            <ModalEditReportHeader
              modal={modalEdit}
              patientsService={props.patientsService}
              patient={patientItem}
              headerTitle="Edit patient information"
              fields={{
                cancerType: true,
                stage: true,
                status: true,
                priorTreatments: true,
                payer: true,
                comorbidities: true,
                address: true,
                groupId: true,
                insuranceId: true,
              }}
            />
          </IonModal>
        </Viewport>
      </IonContent>

      <Footer />
    </IonPage>
  )
}

export default PatientPage
