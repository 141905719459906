export const reportPrintingStyle = `
    * {
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0px;
}


a {
  background-color: transparent;
  -webkit-print-color-adjust: exact;
  color: #0b7ca24d;
}



.ttt-row {
  border-bottom: 1px solid #666;
}
td {
  padding: 10px 5px;
}
.selectable {
  display: flex;
  align-items: center;
}
:not(.ttt-therapy) {
  font-size: 12px;
  color: #666;
}
th:last-child {
    text-align: right;
  }
.tttt-relevant {
  color: #666;
}
.ttt-actions {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
.ttt-checkbox {
  min-width: 20px;
  max-width: 20px;
  margin-right: 16px;
}
.link-title-table {
  color: inherit;
  text-decoration: none;
}


.therapies-trials-table {
  background-color: #ffffff;
  -webkit-print-color-adjust: exact;
  padding: 20px 10px;
  border-radius: 10px;
  text-align: left;
}
table {
  width: 100%;
}
.ttt-header {
  border-bottom: 1px solid #d7d8da;
  font-size: 12px;
  text-transform: uppercase;
  color: #666;
}
th {
  padding: 0 5px;
}
th:last-child {
  text-align: right;
}
.ttt-notes {
  padding-top: 10px;
}
.ttt-show-more {
  margin-top: 20px;
  width: fit-content;
}


.header-toolbar {
  height: 64px;
  display: flex;
  border-bottom: 1px solid #d7d8da;
  align-items: center;
  justify-content: space-around;
}

.header-toolbar-logo {
  flex: 1;
  padding: 16px 24px;
  font-size: 24px;
}
.logo {
  cursor: pointer;
}
.blue {
  color: #0b7ca24d;
}
.header-toolbar-segment {
  display: flex;
  padding: 0px 24px;
  ion-button {
    padding: 0 16px;
  }
}
.header-toolbar-popover {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0px 16px;
  border-left: 1px solid #d7d8da;
  height: 100%;
}

.report-header {
  text-align: left;
}
.patient-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions {
  display: flex;
}
h1 {
  font-size: 36px;
}
.report-header-content {
  display: grid;
  gap: 5%;
  grid-template-columns: 55% 40%;
}
.subcontent {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  span {
    margin-right: 6px;
  }
}
.label {
  display: block;
  text-transform: uppercase;
  font-size: 13px;
}
.report-header-card {
  border: 1px solid #d7d8da;
  border-radius: 10px;
  padding: 10px 16px;
}
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: flex-end;
}
.subcontent,
.label {
  color: #666666;
}
.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 10px 0;
}

.genomic-variants {
  margin: 20px 0;
  border-radius: 8px;
  background-color: #f4f5f8;
  -webkit-print-color-adjust: exact;
  padding: 16px;
}
.gv-custom-title {
  margin-bottom: 10px;
}
.gv-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  
}
.gv-processing {
  display: flex;
  justify-content: center;
  width: 100%;
}
.gv-show-more ion-button {
  width: fit-content;
  margin-top: 20px;
}

.report-page-viewport {
  padding: 24px;
}
.underscore-text {
  text-decoration: underline;
  margin-left: 4px;
}

.progress-note {
  margin-bottom: 20px;
  position: relative;
  background-color: #23395b1a;
  -webkit-print-color-adjust: exact;
  border-radius: 10px;
  padding: 16px;
}

.reply-to-oncologist {
  display: flex;
  padding: 10px;
  justify-content: center;
  background-color: #f5f6f9;
  -webkit-print-color-adjust: exact;
}
ion-button {
  width: fit-content;
}


.barchart {
  display: grid;
  grid-template-columns: 30% calc(70% - 10px);
  align-items: center;
  gap: 10px;
}

.itemLabel {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.barChartItem {
  position: relative;
}
.itemContent {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  position: relative;
  z-index: 2;
  font-size: 10px;
}
.itemBarChart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
}
.percentage1,
.percentage2 {
  height: 100%;
}
.percentage1 {
  background-color: #0b7ca24d !important;
  -webkit-print-color-adjust: exact;
}

.percentage2 {
  background-color: #9888a54d !important;
  -webkit-print-color-adjust: exact;
}


.therapies-trials {
  border-radius: 10px;
  background-color: #f4f5f8;
  -webkit-print-color-adjust: exact;
  padding: 16px;
  text-align: left;
  margin: 20px 0;
}
.table-title {
  margin-bottom: 10px;
}
.tt-title {
  display: flex;
  justify-content: space-between;
}
.treatments-title {
  margin: 16px 0;
}
.tt-selected-itens,
.tt-unselected-itens {
  margin: 16px 0;
}
.tt-spinner {
  display: flex;
  justify-content: center;
}

h3 {
  margin: 0;
}


.genomic-item {
  flex: 1 1;
  box-shadow: none;
  margin: 0;
  border-radius: 10px;
  padding: 6px;
  display: grid;
  grid-template-rows: 33% calc(69% - 16px);
  gap: 16px;
  background-color: #fff;
  -webkit-print-color-adjust: exact;
}
.gi-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.gi-bottom {
  align-self: end;
  overflow: hidden;
}
  .gi-title {
    display: flex;
    justify-content: space-between;
    
  }
  .gi-subtitle {
    font-size: 10px;
    color: #666666;
  }

.gi-action {
  font-size: 12px;
}


  .gi-content-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  li {
    font-size: 10px;
  }
.chart-title {
  font-size: 12px;
  display: block;
  margin-bottom: 8px;
}

.barChartItem {
  margin-bottom: 10px;
}

ion-button {
  margin: 10px 0;
}

.chart-info-item {
  display: flex;
  gap: 8px;
}
.chart-info-ball {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
 .mutation {
  background-color: #0b7ca24d;
  -webkit-print-color-adjust: exact;
}
.amplification {
  background-color: #9888a54d;
  -webkit-print-color-adjust: exact;
}

.immunotherapy-markers-section {
  background-color: #f4f5f8;
  -webkit-print-color-adjust: exact;
  border-radius: 10px;
  padding: 20px;
}
.first-line {
  margin-bottom: 15px;
}
.innercard {
  background-color: #fff;
  -webkit-print-color-adjust: exact;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}
.tm-title {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.tumor-chips {
  border-radius: 36px;
  border: 1px solid #9d9fa6;
  display: flex;
  overflow: hidden;
  height: 36px;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  gap: 20px;
}
.chip {
  background-color: #def7ff;
  -webkit-print-color-adjust: exact;
  font-weight: bold;
  border-radius: 5px;
  padding: 8px;
  max-width: fit-content;
}
.chip-info {
  color: #666;
  font-weight: 400;
  display: block;
  font-size: 12px;
}
.tc-icon {
  background-color: #fff;
  -webkit-print-color-adjust: exact;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #23395b;
  font-size: 20px;
  text-align: center;
}
.no-printing {
  display: none;
}
.only-printing {
  display: block;
}

.High {
  color: #2dd36f;
}
.Medium {
  color: #ffc409;
}
.Low {
  color: #eb445a;
}

`
